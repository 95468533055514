import { gql } from '@apollo/client';
import { RolesData } from './roles_data';

export const GET_ROLES_ALLS = gql`
  query Role_getAll {
    Role_getAll {
      ${RolesData}
    }
  }
`;
