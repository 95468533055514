import React from 'react';
import { Avatar, Dropdown, Menu } from 'antd';
import { config } from '@/config';
import { useAppContext } from '@/core/hooks/useCoreContext';
import { get } from 'lodash';

export const DropdownMenu = () => {
  const avatarUrl = 'https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png';
  const logout = (): void => {
    const currentUrl = window.location.href;
    window.location.href = `${config.base.webUrl}/logout?callbackUrl=${currentUrl}`;
  };
  const { userData } = useAppContext();
  const userName =
    get(userData, 'lastName') && get(userData, 'firstName')
      ? `${get(userData, 'lastName')} ${get(userData, 'firstName')}`
      : get(userData, 'email');

  const menu = (
    <Menu selectedKeys={[]} mode='inline' className='rounded-[10px]'>
      <Menu.Item key='logout' className='rounded-[10px]' onClick={logout}>
        <div className='flex items-center '>
          <i className='bi bi-box-arrow-right text-[#F90404] text-[24px]'></i>
          &nbsp; <span className='text-[10px]'>Log Out</span>
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlayClassName='headerDropdown' className='ml-auto my-[10px]' overlay={menu}>
      <span className='cursor-pointer hover:bg-gray-100 px-[25px] py-[6px]'>
        <Avatar size='small' className='avatar' src={avatarUrl} alt='avatar' />
        <span className='ml-4'>{userName}</span>
      </span>
    </Dropdown>
  );
};
