import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { gql } from '@apollo/client';

export const authorize = async () => {
  return await HttpClientAdapter.query<any>({
    query: gql`
      query User_getPermission {
        User_getPermission
      }
    `,
    dataKey: 'User_getPermission',
  });
};
