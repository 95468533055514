export const UserData = `
id
familyName
givenName
roles {
  id
  name
  isActive
}
email
isActive
permissions
`;
