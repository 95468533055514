export const DashboardData = `
  id
  name
  url
  reportLink
  groupId
  reportId
  isActive
  createdAt
  lastModifiedAt
  roles {
    id
    name
  }
`;
