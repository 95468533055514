import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { CreateMenuItemsPayload } from '@/domain/ports/menu-items/payloads';
import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';

export class UpdateMenuItemUseCase {
  constructor(private readonly repository: MenuItemsRepository) {}

  async update(payload: CreateMenuItemsPayload): Promise<MenuItemsEntity> {
    const menuItem = await this.repository.update(payload);

    return menuItem;
  }
}
