import { gql } from '@apollo/client';
import { ReportItemConfigData } from './reportItemConfigData';

export const GET_REPORT_ITEM_CONFIG = gql`
query Report_getConfig($id: String!) {
  Report_getConfig(query: {
    refresh:true
    dashboardConfig: $id
  }) {
    ${ReportItemConfigData}
  }
}
`;
