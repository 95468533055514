import { PageProvider } from '@/core/context/PageContext';
import useViewModel from '@/modules/menu-items/viewmodels/list';
import React, { useEffect } from 'react';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { FindMenuItemPayload } from '@/domain/ports/menu-items/payloads';
import DeleteMenuItem from '../viewmodels/delete';

interface Data {
  MenuItemList: MenuItemsEntity[];
  actionGetMenuItem: (payload?: FindMenuItemPayload) => void;
  actionDelete: (id: string) => void;
}

export const MenuItemProvider = (props: any) => {
  const {
    pageData: MenuItemData,
    actionGetAll: actionGetMenuItem,
    loading: loadingListMenuItem,
    error: errorListMenuItem,
  } = useViewModel();

  const {
    loading: loadingDeleteMenuItem,
    error: errorDeleteMenuItem,
    actionDelete,
    success: successDeleteMenuItem,
    setSuccess,
  } = DeleteMenuItem();
  const { dataList: MenuItemList } = MenuItemData;
  useEffect(() => {
    actionGetMenuItem();
  }, []);
  const data = {
    MenuItemList,
    actionGetMenuItem,
    loadingListMenuItem,
    errorListMenuItem,
    actionDelete,
    setSuccess,
    loadingDeleteMenuItem,
    errorDeleteMenuItem,
    successDeleteMenuItem,
  };

  return <PageProvider {...data}>{props.children}</PageProvider>;
};
