import { DashboardEntity } from '@/domain/entities/dashboard';
import { CreateDashboardPayload } from '@/domain/ports/dashboard/payloads';
import { DashboardRepository } from '@/domain/ports/dashboard/repositories';

export class CreateDashboardUseCase {
  constructor(private readonly repository: DashboardRepository) {}

  async create(payload: CreateDashboardPayload): Promise<DashboardEntity> {
    // validate check existed
    // const existedModel = await this.repo.getCustomerNeedName(payload.name);
    // if (existedModel) {
    //   throw new ValidationError('CustomerNeed with this name already exist');
    // }

    // Create model
    const dashboard = await this.repository.create(payload);

    return dashboard;
  }
}
