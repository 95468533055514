import { TotalPickUpCallMetricEntity } from '@/domain/entities/real-time';
import { IRealTimeRepository } from '@/domain/ports/real-time/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { REAL_TIME_METRIC_TOTAL_PICKUP_CALL } from '@/modules/real-time/graphql';

export class RealTimeRepository implements IRealTimeRepository {
  async getTotalPickupCallMetric(): Promise<{
    RealTimeMetric_TotalPickUpCall: TotalPickUpCallMetricEntity;
  }> {
    const response = await HttpClientAdapter.query<TotalPickUpCallMetricEntity>({
      query: REAL_TIME_METRIC_TOTAL_PICKUP_CALL,
      dataKey: 'RealTimeMetric_TotalPickUpCall',
      variables: {},
    });
    return {
      RealTimeMetric_TotalPickUpCall: response,
    };
  }
}
