import { gql } from '@apollo/client';
import { UserData } from './account-data';

export const GET_ACCOUNT_ITEMS = gql`
query User_getList($query: QueryUserInput) {
  User_getList(query: $query) {
    data {
      ${UserData}
    }
  }
}
`;

export const UPDATE_ACCOUNT = gql`
mutation User_update($payload: UpdateUserInput!) {
  User_update(updateUserInput: $payload) {
    ${UserData}
  }
}
`;

export const FIND_ACCOUNT = gql`
query User_getById($id: String!) {
  User_getById(id: $id) {
    ${UserData}
  }
}
`;

export const FIND_PERMISSION = gql`
  query User_getPermission {
    User_getPermission
  }
`;
