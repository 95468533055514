import { Authorize } from '@/core';
import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import useViewModel from '@/modules/dashboard/viewmodels/list';
import { ErrorPage, ReportItem } from '@/modules';

const DefaultPageScreen: React.FC<unknown> = (): any => {
  const { dashboardData, actionGetDashboard } = useViewModel();
  const { dashboardList } = dashboardData;
  useEffect(() => {
    actionGetDashboard({
      isActive: true,
    });
  }, []);
  if (dashboardList.length > 0) {
    return (
      <>
        <Routes>
          {dashboardList.map((item) => {
            return <Route key={item.id} path={item.url} element={<ReportItem />} />;
          })}
          <Route path='*' element={<ErrorPage />} />
        </Routes>
      </>
    );
  }
};
export const DefaultPage = Authorize<unknown>(DefaultPageScreen);
