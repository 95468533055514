import { RolesEntity } from '@/domain/entities/roles';
import { RolesRepository } from '@/domain/ports/roles/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_ROLES_ALLS } from '../../graphql';

export class RolesRepoIml implements RolesRepository {
  async getAll(): Promise<{ Role_getAll: RolesEntity[] }> {
    const response: { Role_getAll: RolesEntity[] } = await HttpClientAdapter.query<{
      Role_getAll: RolesEntity[];
    }>({
      query: GET_ROLES_ALLS,
    });
    return response;
  }
}
