import { Control } from 'react-hook-form';
import CoreSelect from '../Form/SelectAntForm';
import React from 'react';
import { Typography } from 'antd';

interface Props {
  control: Control;
  className?: string;
}

const options = [
  {
    value: true,
    label: 'Active',
  },
  {
    value: false,
    label: 'Deactive',
  },
];

export const CoreFilterActive = (props: Props) => {
  const { control, className, ...restProps } = props;

  return (
    <div className={className}>
      <Typography>Status</Typography>
      <CoreSelect control={control} name='isActive' optionsProps={options} {...restProps} />
    </div>
  );
};
