import CoreDrawer from '@/components/CoreDrawer';
import { DetailProps } from '@/domain/entities/detail';
import { Spin, Typography } from 'antd';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// import { InputAntForm } from '@/components/Form/InputAntForm';
import useViewModel from '../../viewmodels/detail';
import { MenuItemForm } from './MenuItemForm';

export const Detail: React.FC<DetailProps> = (props: DetailProps): JSX.Element => {
  const { open, handleClose, setOpen, setRefresh } = props;
  const { loading, error, detail, actionGetDetail } = useViewModel();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const getAction = currentQueryParameters.get('action');

  useEffect(() => {
    if (
      (showId !== null && getAction === 'Detail') ||
      (showId === null && getAction === 'Create')
    ) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [open, handleClose, setOpen]);

  useEffect(() => {
    if (showId) {
      actionGetDetail();
    }
  }, [open]);

  const renderContent = () => {
    if (showId) {
      return (
        <>
          <div>{loading && <Spin />}</div>
          {error && <div>Error</div>}
          {!error && !loading && get(detail, 'data.id') !== '' && (
            <MenuItemForm
              detailMenuItem={get(detail, 'data')}
              setRefresh={setRefresh}
              openForm={open}
              showId={showId}
            />
          )}
        </>
      );
    }
    return (
      <MenuItemForm
        detailMenuItem={get(detail, 'data')}
        setRefresh={setRefresh}
        openForm={open}
        showId={showId}
      />
    );
  };

  return (
    <CoreDrawer
      open={open}
      onClose={() => handleClose()}
      title={
        <div>
          <Typography.Title level={5}>Menu Item</Typography.Title>
        </div>
      }
      content={renderContent()}
    />
  );
};
