import { menuIcon } from '@/components/helps/ListOptionsIcon';
import React from 'react';

export const findIcons = (value: string) => {
  const Icons = menuIcon.find((item) => item.value === value);
  if (Icons) {
    // todo
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return Icons?.label;
  }
  return '';
};
