import React from 'react';

import { useSSOAuthentication } from '@/core/hooks';
import { Spin, FullView } from '@/components';

import './index.css';
import { NoPermissionAccess } from '@/modules/403/NoPermissionAccess';
interface AuthorizeParams {
  Component: any;
  permission: any;
  authenticationRequired?: boolean;
  noAdminLayout?: boolean;
}

export function Authorize<T>(Component: React.FC<T>, permission?: string): React.FC<T> {
  // eslint-disable-next-line react/display-name
  return (props: any) => {
    const { isAuthenticated, isAllow } = useSSOAuthentication(true, permission);
    if (isAuthenticated) {
      if (isAllow) {
        return <Component {...props} />;
      } else {
        return <NoPermissionAccess />;
      }
    }
    return (
      <FullView className='container-center'>
        <Spin />
      </FullView>
    );
  };
}
