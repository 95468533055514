import { CoreInput } from '@/components/Form/InputAntForm';
import { CoreSwitch } from '@/components/Form/Switch';
import CoreSelectIcon from '@/components/helps/ListOptionsIcon';
import { Button, Typography } from 'antd';
import React, { useEffect } from 'react';
import { SelectMenuItemParent } from '../../components/SelectMenuItemParent';
import useViewModel from '../../viewmodels/form';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { SelectReport } from '../../components/SelectReport';

interface Props {
  detailMenuItem: MenuItemsEntity;
  setRefresh: (refresh: boolean) => void;
  openForm: boolean;
  showId: string | null;
}

export const MenuItemForm = (props: Props) => {
  const { detailMenuItem, openForm, showId } = props;
  const { methodForm, onSubmit, defaultValue } = useViewModel(props);

  const {
    control,
    reset,
    formState: { isSubmitting, isDirty },
  } = methodForm;

  useEffect(() => {
    if (!showId) {
      reset(defaultValue);
    }
  }, [openForm]);

  return (
    <form onSubmit={onSubmit}>
      <div className='w-full flex flex-wrap'>
        <Typography.Text className='w-full'>Item name (*)</Typography.Text>
        <CoreInput control={control} name='name' className='w-full mb-10' />
        <div className='w-full flex flex-wrap mb-4'>
          <div className='w-1/2'>
            <Typography.Text className='w-full'>Item parent</Typography.Text>
            <SelectMenuItemParent
              control={control}
              name='parent'
              className='w-full'
              depth={showId !== null ? detailMenuItem.level : 3}
              openForm={openForm}
            />
          </div>
          <div className='text-left ml-6'>
            <Typography.Text className='w-full'>Status</Typography.Text>
            <CoreSwitch control={control} name='isActive' className='my-auto ' />
          </div>
        </div>
        <div className='w-full flex flex-wrap mb-4'>
          <div className='w-1/2'>
            <Typography.Text className='w-full'>Icon (*)</Typography.Text>
            <CoreSelectIcon control={control} name='icon' className='text-center' />
          </div>
          <div className='w-1/2'>
            <Typography.Text className='ml-6'>report (*)</Typography.Text>
            <SelectReport
              control={control}
              name='dashboardConfig'
              className='ml-6'
              openForm={openForm}
              item={detailMenuItem.dashboardConfig !== null ? detailMenuItem.dashboardConfig : null}
            />
          </div>
        </div>
        <Typography.Text className='w-full'>Description</Typography.Text>
        <CoreInput
          control={control}
          name='description'
          type='area'
          row={5}
          className='w-full mb-10'
        />
      </div>
      <div className='text-right'>
        <Button type='primary' htmlType='submit' loading={isSubmitting} disabled={!isDirty}>
          Save
        </Button>
      </div>
    </form>
  );
};
