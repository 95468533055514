import { useBaseViewModel } from '@/common';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { FindMenuItemPayload } from '@/domain/ports/menu-items/payloads';
import { MenuItemsListUseCase } from '@/domain/use-cases/menu-items';
import { useState } from 'react';
import { MenuItemRepoIml } from '../adapters/repositories';

export default function GetParentMenuItem() {
  const getParentMenuItem = new MenuItemsListUseCase(new MenuItemRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [parent, setParent] = useState<{ dataList: MenuItemsEntity[] }>({ dataList: [] });

  const actionGetParent = (payload?: FindMenuItemPayload) => {
    catchAction(async () => {
      const { data } = await getParentMenuItem.run(payload);
      setParent({ dataList: data });
    });
  };

  return {
    loading,
    error,
    parent,
    actionGetParent,
  };
}
