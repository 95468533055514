import { ApolloClient, InMemoryCache } from '@apollo/client';
// import { onError } from '@apollo/client/link/error';
import { apiUrl } from './config';
import { getAuth } from 'firebase/auth';

// const errorLink = onError(({ graphQLErrors, networkError }) => {
//   if (graphQLErrors)
//     graphQLErrors.forEach(({ message, locations, path, extensions }) => {
//       console.log(
//         `>>> [GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
//       );
//       console.log({ extensions });
//     });
// });
//
// const httpLink = new HttpLink({ uri: apiUrl });

// export const httpClient = new ApolloClient({
//   cache: new InMemoryCache(),
//   link: from([errorLink, httpLink]),
// });

export const initApolloClient = async (): Promise<ApolloClient<any>> => {
  const auth = getAuth();
  const idToken = await auth.currentUser?.getIdToken();
  const userId = await auth.currentUser?.uid;

  return new ApolloClient({
    cache: new InMemoryCache(),
    uri: apiUrl,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    credentials: 'include',
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
  });
};
