import { useBaseViewModel } from '@/common';
import { UsersEntity } from '@/domain/entities/users';
import { FindFirebaseIdUsecase } from '@/domain/use-cases/users/findFireBaseId';
import { useState } from 'react';
import { UsersRepoIml } from '../adapters/repositories';

export const getUserByFirebaseId = () => {
  const getUserByFirebaseId = new FindFirebaseIdUsecase(new UsersRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [userData, setUserData] = useState<UsersEntity>({
    id: '',
    email: '',
    givenName: '',
    roles: [''],
    isActive: true,
    permissions: [''],
  });

  const actionGetUserByFirebaseId = (id: string) => {
    if (id) {
      catchAction(async () => {
        const data = await getUserByFirebaseId.run(id);
        setUserData(data);
      });
    }
  };

  return {
    loading,
    error,
    userData,
    actionGetUserByFirebaseId,
  };
};
