import { useBaseViewModel } from '@/common';
import { DashboardEntity } from '@/domain/entities/dashboard';
import { FindDashboardByUseCase } from '@/domain/use-cases/dashboard/find';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { DashboardRepoIml } from '../adapters/repositories';

export default function GetDetailDashboard() {
  const getDetailDashboard = new FindDashboardByUseCase(new DashboardRepoIml());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading, error, catchAction } = useBaseViewModel();
  const [detail, setDetail] = useState<{ data: DashboardEntity }>({
    data: {
      id: '',
      name: '',
      isActive: true,
      roles: [],
      url: '',
      groupId: '',
      reportId: '',
      reportLink: '',
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const { dataDetail } = await getDetailDashboard.find(getId);
        setDetail({ data: dataDetail });
      });
    }
  };

  return {
    loading,
    error,
    detail,
    actionGetDetail,
  };
}
