import { EmbedReportUseCase } from '@/domain/use-cases/embed-report';
import { get } from 'lodash';
import { Button, Spin } from 'antd';
import { EmbedReportRepoImpl } from '../adapters/repositories';
import { useBaseViewModel } from '@/common';
import React, { useCallback, useEffect } from 'react';
import { useZoom } from '../hooks/useZoom';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { NoPermissionView } from '@/modules/403/NoPermissionView';

interface Props {
  reportData: any;
}

const ReportItemData = (props: Props) => {
  const reportConfig = new EmbedReportUseCase(new EmbedReportRepoImpl());
  const { reportData } = props;
  const { loading, error, catchAction, setError } = useBaseViewModel();
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const pbi = require('powerbi-client');
  const mainReportStorageKey = 'MAIN_REPORT';
  const { zoomRate, zoomIn, zoomOut } = useZoom(300, 50, mainReportStorageKey);
  const getItem = (item: any) => {
    catchAction(async () => {
      const { data } = await reportConfig.run(item.id);
      const embedConfigs = await {
        type: 'report',
        id: item.reportId,
        group: item.groupId,
        embedUrl: get(data, 'embedUrl'),
        tokenType: pbi.models.TokenType.Aad,
        accessToken: get(data, 'embedToken'),
      };

      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory,
        pbi.factories.wpmpFactory,
        pbi.factories.routerFactory,
      );
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      powerbi.embedNew(document.getElementById(item.id)!, embedConfigs);
    });
  };

  useEffect(() => {
    getItem(reportData);
    setError(undefined);
  }, [reportData]);
  return (
    <div
      style={{
        height: 'calc(100vh - 95px)',
        position: 'relative',
        overflow: 'auto',
      }}
    >
      <div>
        {loading && (
          <div
            style={{
              height: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </div>
      {error && <NoPermissionView />}
      {!error && (
        <div
          id={reportData.id}
          style={{
            transition: ' all 0.5s',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            width: `${(100 * zoomRate) / 100}%`,
            paddingTop: `${(50 * zoomRate) / 100}%`,
          }}
          // eslint-disable-next-line react/no-unknown-property
          powerbi-settings-filter-pane-enabled='false'
        />
      )}
      <div className='zoom-controller'>
        <div style={{ fontSize: '0.75rem' }}>{zoomRate}%</div>
        <Button
          icon={<ZoomInOutlined />}
          size='small'
          style={{ margin: '0.25rem 0' }}
          onClick={zoomIn}
          disabled={zoomRate >= 300}
        />
        <Button
          icon={<ZoomOutOutlined />}
          size='small'
          onClick={zoomOut}
          disabled={zoomRate <= 50}
        />
      </div>
    </div>
  );
};

export default ReportItemData;
