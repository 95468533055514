import { AccountsRepository } from '@/domain/ports/account/repositories';

export class AccountPermissionsUseCase {
  constructor(private readonly repository: AccountsRepository) {}

  async run() {
    const data = await this.repository.getPermission();
    return data;
  }
}
