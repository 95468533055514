import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { DashboardEntity } from '@/domain/entities/dashboard';
import { CreateDashboardPayload } from '@/domain/ports/dashboard/payloads';
import { CreateDashboardUseCase } from '@/domain/use-cases/dashboard/create';
import { UpdateDashboardUseCase } from '@/domain/use-cases/dashboard/update';
import yup from '@/yupGlobal';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { DashboardRepoIml } from '../adapters/repositories';

interface Props {
  detailDashboard: DashboardEntity;
  setRefresh: (refresh: boolean) => void;
}

const CreateDashboard = (props: Props) => {
  const { detailDashboard, setRefresh } = props;
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createDashboard = new CreateDashboardUseCase(new DashboardRepoIml());
  const updateDashboard = new UpdateDashboardUseCase(new DashboardRepoIml());
  const [isError, setIsError] = useState(false);
  const formatRoles = useCallback(() => {
    if (Array.isArray(detailDashboard.roles) && detailDashboard.roles.length > 0) {
      return detailDashboard.roles.map((item) => {
        return item.id;
      });
    }

    return [];
  }, [detailDashboard]);

  const defaultValue = {
    id: '',
    name: '',
    isActive: true,
    roles: [],
    url: '',
    groupId: '',
    reportId: '',
    reportLink: '',
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailDashboard?.id ?? '',
      name: detailDashboard?.name ?? '',
      isActive: detailDashboard.isActive ?? true,
      roles: formatRoles(),
      url: detailDashboard.url ?? '',
      groupId: detailDashboard.groupId ?? '',
      reportId: detailDashboard.reportId ?? '',
      reportLink: detailDashboard.reportLink ?? '',
    },
    resolver: yupResolver(
      yup.object({
        name: yup
          .string()
          .required('Dashboard name is required')
          .min(2, 'Too short')
          .max(40, 'Limited 40 character included: space, *,%,+,-,...'),
        url: yup
          .string()
          .required('Url is required')
          .trim()
          .test('testUrl', '', () => {
            if (isError) {
              setIsError(false);
            }
            return true;
          }),
        roles: yup.array().min(1, 'Allowed role is required'),
        reportLink: yup
          .string()
          .required('Report link is required')
          .test('testReportUrl', 'The link is not correct', (val) => {
            if (
              (val && methodForm.watch('reportId') && methodForm.watch('groupId')) ||
              val === ''
            ) {
              return true;
            }
            return false;
          }),
      }),
    ),
  });

  const onSubmit = methodForm.handleSubmit(async (data: CreateDashboardPayload) => {
    try {
      const roles = data.roles.map((role) => (typeof role === 'string' ? role : ''));
      const newData = { ...data, roles };
      if (!showId) {
        delete newData.id;
        await createDashboard.create(newData);
        notification.success({
          message: 'You already have successfully created dashboard',
          placement: 'bottomRight',
        });
      } else {
        await updateDashboard.update(newData);
        notification.success({
          message: 'You already have successfully updated dashboard',
          placement: 'bottomRight',
        });
      }
      setRefresh(true);
      handleClose();
      methodForm.reset({
        ...defaultValue,
      });
    } catch (error) {
      if (getErrorMessage(error) === 'Dashboard with url already exist') {
        setIsError(true);
      }

      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    defaultValue,
    isError,
  };
};

export default CreateDashboard;
