import CoreSelect from '@/components/Form/SelectAntForm';
import React, { useCallback, useEffect } from 'react';
import useViewDashboardModel from '../../dashboard/viewmodels/list';

interface Props {
  control: any;
  name: string;
  className?: string;
  openForm: boolean;
  label?: string;
  required?: boolean;
  item: any;
}

export const SelectReport = (props: Props) => {
  const { control, name, className, openForm, label, required } = props;
  const { dashboardData: allDashboard, actionGetDashboard } = useViewDashboardModel();

  useEffect(() => {
    actionGetDashboard();
  }, [openForm]);

  const options = useCallback(() => {
    if (Array.isArray(allDashboard.dashboardList) && allDashboard.dashboardList.length > 0) {
      const formatData = allDashboard.dashboardList.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      return formatData;
    }
    return [];
  }, [allDashboard]);

  return (
    <CoreSelect
      control={control}
      name={name}
      optionsProps={options()}
      className={className}
      label={label}
      required={required}
    />
  );
};
