import { RolesRepository } from '@/domain/ports/roles/repositories';

export class AllRolesUseCase {
  constructor(private readonly repository: RolesRepository) {}

  async run() {
    const data = await this.repository.getAll();
    return data;
  }
}
