import { Drawer, DrawerProps } from 'antd';
import React from 'react';

type Props = Omit<DrawerProps, 'placement' | 'closable' | 'keyboard'> & {
  content?: React.ReactNode;
};

const CoreDrawer: React.FC<Props> = (props: Props): JSX.Element => {
  const { width = 1000, content, onClose, open, title, ...restProps } = props;
  return (
    <Drawer
      {...restProps}
      width={width}
      placement='right'
      closable={false}
      keyboard={false}
      open={open}
      onClose={onClose}
      title={title}
    >
      {content}
    </Drawer>
  );
};

export default React.memo(CoreDrawer);
