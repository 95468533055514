import { Authorize } from '@/core';
import { Button, Spin } from 'antd';
import React, { useEffect } from 'react';
import useViewModel from './viewmodels';

import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { useZoom } from '../report-item/hooks/useZoom';
import { PERMISSIONS } from '@/core/constants/permission.constant';
const ReportScreen: React.FC<unknown> = () => {
  const { loading, error, actionGetAll } = useViewModel();
  const mainReportStorageKey = 'MAIN_REPORT';
  const { zoomRate, zoomIn, zoomOut } = useZoom(300, 50, mainReportStorageKey);

  useEffect(() => {
    actionGetAll();
  }, []);

  return (
    <div
      style={{
        // height: 'calc(100vh - 120px)',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: 'white',
      }}
    >
      {/* <div>
        {loading && (
          <div
            style={{
              height: '500px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spin />
          </div>
        )}
      </div>
      {error && <div>Error</div>}
      {!error && (
        <div
          id='powerbi-report'
          style={{
            transition: ' all 0.5s',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
            width: `${(100 * zoomRate) / 100}%`,
            paddingTop: `${(50 * zoomRate) / 100}%`,
          }}
          // eslint-disable-next-line react/no-unknown-property
          powerbi-settings-filter-pane-enabled='false'
          
        >
        </div>
        
      )}
      <div className='zoom-controller'>
        <div style={{ fontSize: '0.75rem' }}>{zoomRate}%</div>
        <Button
          icon={<ZoomInOutlined />}
          size='small'
          style={{ margin: '0.25rem 0' }}
          onClick={zoomIn}
          disabled={zoomRate >= 300}
        />
        <Button
          icon={<ZoomOutOutlined />}
          size='small'
          onClick={zoomOut}
          disabled={zoomRate <= 50}
        />
      </div> */}
      <img src={require('@/static/images/hello_page.png')} className='m-auto'></img>
    </div>
  );
};

export const Report = Authorize<unknown>(ReportScreen, PERMISSIONS.APP.VIEW);
// export const Report = ReportScreen;
