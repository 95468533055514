import { DashboardEntity } from '@/domain/entities/dashboard';
import { CreateDashboardPayload, FindDashboardPayload } from '@/domain/ports/dashboard/payloads';
import { DashboardRepository } from '@/domain/ports/dashboard/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CREATE_DASHBOARD,
  EDIT_DASHBOARD,
  FIND_DASHBOARD,
  DELETE_DASHBOARD,
  GET_DASHBOARD,
} from '../../graphql';

export class DashboardRepoIml implements DashboardRepository {
  async get(payload?: FindDashboardPayload): Promise<{ data: DashboardEntity[] }> {
    const response: DashboardEntity[] = await HttpClientAdapter.query<DashboardEntity[]>({
      query: GET_DASHBOARD,
      dataKey: 'Dashboard_getList',
      variables: { query: { ...payload } },
    });
    return {
      data: response,
    };
  }

  async create(payload: CreateDashboardPayload): Promise<DashboardEntity> {
    const response = await HttpClientAdapter.mutate<DashboardEntity>({
      mutation: CREATE_DASHBOARD,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async update(payload: CreateDashboardPayload): Promise<DashboardEntity> {
    const response = await HttpClientAdapter.mutate<DashboardEntity>({
      mutation: EDIT_DASHBOARD,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async delete(payload: string): Promise<{ dataDeleted: DashboardEntity }> {
    const response: DashboardEntity = await HttpClientAdapter.mutate<DashboardEntity>({
      mutation: DELETE_DASHBOARD,
      variables: { id: payload },
    });
    return {
      dataDeleted: response,
    };
  }

  async find(payload: string): Promise<{ dataDetail: DashboardEntity }> {
    const response: DashboardEntity = await HttpClientAdapter.query<DashboardEntity>({
      query: FIND_DASHBOARD,
      dataKey: 'Dashboard_findById',
      variables: { id: payload },
    });
    return {
      dataDetail: response,
    };
  }
}
