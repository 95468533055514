import { useRealtimeDashboardViewModel } from '@/modules/real-time/viewmodels/realtime-dashboard.view-model';
import { Column } from '@ant-design/plots';
import { Card, Col, Row, Statistic, StatisticProps } from 'antd';
import React from 'react';
import CountUp from 'react-countup';

export const RealtimeDashboard = () => {
  const { chartData } = useRealtimeDashboardViewModel(30000);
  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator=',' />
  );
  const config = {
    data: chartData.topUsers,
    xField: 'userName',
    yField: 'totalCalls',
    title: 'Top 10 Salemans theo số cuộc gọi nhấc máy',
  };
  return (
    <>
      <Row gutter={16} className={'mb-2'}>
        <Col span={18}>
          <Row gutter={16}>
            <Col span={6}>
              <Card>
                <Statistic
                  title='Tổng cuộc gọi'
                  value={chartData.totalPickUpCall}
                  formatter={formatter}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col span={6}></Col>
      </Row>
      <Row gutter={16}>
        <Col span={8}>
          <div style={{ background: '#fff' }}>
            <Column colorField={'#e31f26'} {...config} theme={'academy'} />
          </div>
        </Col>
      </Row>
    </>
  );
};
