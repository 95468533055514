import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { CreateMenuItemsPayload, FindMenuItemPayload } from '@/domain/ports/menu-items/payloads';
import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import {
  CREATE_MENU_ITEM,
  EDIT_MENU_ITEM,
  FIND_MENU_ITEM,
  DELETE_MENU_ITEM,
  GET_MENU_ITEMS,
  GET_TREE_MENU_ITEMS,
} from '../../graphql';

export class MenuItemRepoIml implements MenuItemsRepository {
  async get(payload?: FindMenuItemPayload): Promise<{ data: MenuItemsEntity[] }> {
    const response: MenuItemsEntity[] = await HttpClientAdapter.query<MenuItemsEntity[]>({
      query: GET_MENU_ITEMS,
      dataKey: 'MenuItem_getAll',
      variables: { query: { ...payload } },
    });
    return {
      data: response,
    };
  }
  async getTree(): Promise<{ data: MenuItemsEntity[] }> {
    const response: MenuItemsEntity[] = await HttpClientAdapter.query<MenuItemsEntity[]>({
      query: GET_TREE_MENU_ITEMS,
      dataKey: 'MenuItem_getTree',
    });
    return {
      data: response,
    };
  }

  async create(payload: CreateMenuItemsPayload): Promise<MenuItemsEntity> {
    const response = await HttpClientAdapter.mutate<MenuItemsEntity>({
      mutation: CREATE_MENU_ITEM,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async update(payload: CreateMenuItemsPayload): Promise<MenuItemsEntity> {
    const response = await HttpClientAdapter.mutate<MenuItemsEntity>({
      mutation: EDIT_MENU_ITEM,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async delete(payload: string): Promise<{ dataDeleted: MenuItemsEntity }> {
    const response: MenuItemsEntity = await HttpClientAdapter.mutate<MenuItemsEntity>({
      mutation: DELETE_MENU_ITEM,
      variables: { id: payload },
    });
    return {
      dataDeleted: response,
    };
  }

  async find(payload: string): Promise<{ dataDetail: MenuItemsEntity }> {
    const response: MenuItemsEntity = await HttpClientAdapter.query<MenuItemsEntity>({
      query: FIND_MENU_ITEM,
      dataKey: 'MenuItem_findById',
      variables: { id: payload },
    });
    return {
      dataDetail: response,
    };
  }
}
