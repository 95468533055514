import { useEffect, useState } from 'react';
import { getCustomTokenFromSSOServer, authenticate, navigateOnFailures } from '@/core';
import '@/core/firebase';
import { authorize } from '@/core/auth/authorize';

export const useSSOAuthentication = (authenticationRequired: boolean, permission?: string) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>(undefined);
  const [isAllow, setIsAllow] = useState<boolean | undefined>(undefined);

  const execSSOAuthenticate = async () => {
    try {
      const customToken = await getCustomTokenFromSSOServer();
      const rs = await authenticate(customToken);
      const { userId: newUserId } = rs;
      if (permission) {
        const permissions = await authorize();
        const isAllow = permissions.includes(permission);
        setIsAllow(isAllow);
      }
      if (newUserId) {
        setIsAuthenticated(true);
      } else {
        // setIsAuthorized(false);
      }
    } catch (error) {
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      execSSOAuthenticate().catch(console.error);
    }
  }, []);

  navigateOnFailures(isAuthenticated, authenticationRequired);

  return {
    isAuthenticated,
    isAllow,
  };
};
