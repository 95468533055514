import { useLocalStorage } from '@/components/hooks/useLocalStorage';

export const useZoom = (maxZoom: number, minZoom: number, key: string) => {
  const [zoomRate, setZooomRate] = useLocalStorage(key, 100);

  const zoomIn = () => {
    if (zoomRate < maxZoom) {
      setZooomRate(zoomRate + 25);
    }
  };
  const zoomOut = () => {
    if (zoomRate > minZoom) {
      setZooomRate(zoomRate - 25);
    }
  };
  return {
    zoomRate,
    zoomIn,
    zoomOut,
  };
};
