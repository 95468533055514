import { FindDashboardPayload } from '@/domain/ports/dashboard/payloads';
import { DashboardRepository } from '@/domain/ports/dashboard/repositories';

export class DashboardListUseCase {
  constructor(private readonly repository: DashboardRepository) {}

  async run(payload?: FindDashboardPayload) {
    const { data } = await this.repository.get(payload);
    return { data };
  }
}
