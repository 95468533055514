export const UsersData = `
id
email
givenName
firstName
lastName
roles
isActive
permissions
`;
