import { DashboardEntity } from '@/domain/entities/dashboard';
import { DashboardRepository } from '@/domain/ports/dashboard/repositories';

export class DeleteDashboardUseCase {
  constructor(private readonly repository: DashboardRepository) {}

  async delete(payload: string): Promise<{ dataDeleted: DashboardEntity }> {
    const { dataDeleted } = await this.repository.delete(payload);

    return { dataDeleted };
  }
}
