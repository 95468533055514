import { Button, Result } from 'antd';
import React from 'react';

export const NoPermissionView: React.FC = () => (
  <Result
    status='403'
    title='403'
    subTitle='Sorry, you are not authorized to access this page. Please refer to your adminstrator.'
  />
);
