import { powerbiUrlLink } from './config';

export const useHandleReportLink = (props: string) => {
  const link = props;
  if (link !== '') {
    const reportLink = link.split('//');
    if (reportLink[0] === 'http:' || reportLink[0] === 'https:') {
      const protocol = reportLink[0] + '//';
      const host = reportLink[1].split('/')[0];
      const url = protocol + host + '/';
      const path = link.split(url)[1];
      if (host === powerbiUrlLink) {
        const pathItem = path.split('/');
        if (pathItem[0] === 'groups' && pathItem[2] === 'reports') {
          return {
            groupId: pathItem[1],
            reportId: pathItem[3],
          };
        }
      }
    }
  }
  return {
    groupId: '',
    reportId: '',
  };
};
