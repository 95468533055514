import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { Authorize, useSSOAuthentication } from '@/core';
import CoreTable from '@/components/Table';
import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { MenuItemTableColumns } from '../../components';
import useViewModel from '../../viewmodels/list';
import { Detail } from '../Form';
import { useAppContext, useCoreContext } from '@/core/hooks/useCoreContext';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import { useCheckPermission } from '@/core/hooks/useCheckPermission';

export const MenuItemListScreen: React.FC<unknown> = () => {
  const {
    actionGetMenuItem: actionGetAll,
    loadingDeleteMenuItem: loading,
    errorDeleteMenuItem: error,
    MenuItemList,
  } = useCoreContext();
  const [refresh, setRefresh] = useState(false);
  const tableColumns = MenuItemTableColumns(setRefresh);
  const { permission } = useAppContext();
  const { ensurePermission } = useCheckPermission();
  const isAllowCreate = ensurePermission(permission, PERMISSIONS.MENU_ITEMS.CREATE);

  const { open, handleClose, setOpen, handleClickCreate } = useHandleActionItem();

  useEffect(() => {
    actionGetAll({ limit: 100 });
  }, []);

  useEffect(() => {
    if (refresh === true) {
      actionGetAll();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <div className='bg-white py-10 flex flex-col flex-wrap'>
      <div className='ml-auto mr-4'>
        {isAllowCreate ? (
          <Button
            type='primary'
            size='middle'
            onClick={() => handleClickCreate()}
            className='mr-6 rounded'
          >
            + Create
          </Button>
        ) : (
          ''
        )}
        {/* <Button type='primary' size='large' onClick={() => actionGetAll()}>
          Refresh
        </Button> */}
      </div>
      <div className='text-center'>{loading && <Spin className='items-center' />}</div>
      {error && <div>Error</div>}
      {!error && (
        <div>
          <div className='mt-8 w-full'>
            <CoreTable
              columns={tableColumns}
              dataSource={MenuItemList ?? []}
              tableName='Menu-items'
            />
          </div>
        </div>
      )}
      <Detail open={open} handleClose={handleClose} setOpen={setOpen} setRefresh={setRefresh} />
    </div>
  );
};
