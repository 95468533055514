import { useBaseViewModel } from '@/common';
import { EmbedReportUseCase } from '@/domain/use-cases/embed-report';
import { get } from 'lodash';
import { EmbedReportRepoImpl } from '../adapters/repositories';

export default function EmbedReportPowerBI() {
  const reportConfig = new EmbedReportUseCase(new EmbedReportRepoImpl());

  const { loading, error, catchAction } = useBaseViewModel();

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const pbi = require('powerbi-client');
  const actionGetAll = () => {
    catchAction(async () => {
      // const { data } = await reportConfig.run();
      // const embedConfigs = {
      //   type: 'report',
      //   id: get(data, 'reportId'),
      //   embedUrl: get(data, 'embedUrl'),
      //   tokenType: pbi.models.TokenType.Aad,
      //   accessToken: get(data, 'embedToken'),
      // };
      // const powerbi = new pbi.service.Service(
      //   pbi.factories.hpmFactory,
      //   pbi.factories.wpmpFactory,
      //   pbi.factories.routerFactory,
      // );
      // // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      // powerbi.embedNew(document.getElementById('powerbi-report')!, embedConfigs);
    });
  };

  return {
    actionGetAll,
    loading,
    error,
  };
}
