import {
  CreditCardOutlined,
  FastBackwardOutlined,
  FundOutlined,
  HomeOutlined,
  ProjectOutlined,
  ShopOutlined,
  ShoppingOutlined,
  StockOutlined,
  TagOutlined,
} from '@ant-design/icons';
import React from 'react';
import CoreSelect from '../Form/SelectAntForm';

export interface PropsCoreSelect {
  label?: string;
  placeholder?: string;
  control: any;
  name: string;
  displayField?: string;
  valueField?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  value?: any;
  allowClear?: boolean;
  disabled?: boolean;
  onChange?: (value: any) => void;
}

export const optionsIcon = [
  {
    value: 'Home',
    label:
      '<div style="display: flex; align-items: center; align-items: center;"><i class="material-symbols-outlined">home</i><a style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Home</a></div>',
  },
  {
    value: 'Payment',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">payment</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Payment</span></div>',
  },
  {
    value: 'Shopping Bag',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">shopping_bag</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Shopping Bag</span></div>',
  },
  {
    value: 'Receipt Long',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">receipt_long</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Receipt Long</span></div>',
  },
  {
    value: 'Store Front',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">storefront</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Store Front</span></div>',
  },
  {
    value: 'Analytics',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">analytics</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Analytic</span></div>',
  },
  {
    value: 'Loyalty',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">loyalty</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Loyalty</span></div>',
  },
  {
    value: 'Chart Data',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">chart_data</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Chart Data</span></div>',
  },
  {
    value: 'Show Chart',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">show_chart</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Show Chart</span></div>',
  },
  {
    value: 'Track Changes',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">track_changes</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Track Changes</span></div>',
  },
  {
    value: 'Pie Chart',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">pie_chart</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Pie Chart</span></div>',
  },
  {
    value: 'Leaderboard',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">leaderboard</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Leaderboard</span></div>',
  },
  {
    value: 'Dashboard',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">dashboard</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Dashboard</span></div>',
  },
  {
    value: 'Person',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">person</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Person</span></div>',
  },
  {
    value: 'School',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">shopping_bag</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">School</span></div>',
  },
  {
    value: 'Campaign',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">campaign</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Campaign</span></div>',
  },
  {
    value: 'Productivity',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">productivity</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Productivity</span></div>',
  },
  {
    value: 'Phone In Talk',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">phone_in_talk</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Phone in talk</span></div>',
  },
  {
    value: 'Tools Wrench',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">tools_wrench</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Tool Wrench</span></div>',
  },
  {
    value: 'Star',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">star</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Star</span></div>',
  },
  {
    value: 'Settings Accessibility',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">settings_accessibility</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Settings Accessibility</span></div>',
  },
  {
    value: 'View Chart',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">view_kanban</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">View Chart</span></div>',
  },
  {
    value: 'Patient List',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">patient_list</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Customer List</span></div>',
  },
  {
    value: 'Group',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">group</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Group</span></div>',
  },
  {
    value: 'Lock Person',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">lock_person</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Lock Person</span></div>',
  },
  {
    value: 'Interests',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">interests</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Interests</span></div>',
  },
  {
    value: 'Manage Accounts',
    label:
      '<div style="display: flex; align-items: center;"><i class="material-symbols-outlined">manage_accounts</i><span style="font-size: 14px; padding-left: 4px; margin-top: 6px;">Account Setting</span></div>',
  },
];

export const menuIcon = [
  {
    value: 'Home',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        home
      </span>
    ),
  },
  {
    value: 'Payment',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        payment
      </span>
    ),
  },
  {
    value: 'Shopping Bag',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        shopping_bag
      </span>
    ),
  },
  {
    value: 'Receipt Long',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        receipt_long
      </span>
    ),
  },
  {
    value: 'Store Front',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        storefront
      </span>
    ),
  },
  {
    value: 'Analytics',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        analytics
      </span>
    ),
  },
  {
    value: 'Loyalty',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        loyalty
      </span>
    ),
  },
  {
    value: 'Chart Data',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        chart_data
      </span>
    ),
  },
  {
    value: 'Show Chart',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        show_chart
      </span>
    ),
  },
  {
    value: 'Track Changes',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        track_changes
      </span>
    ),
  },
  {
    value: 'Pie Chart',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        pie_chart
      </span>
    ),
  },
  {
    value: 'Leaderboard',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        leaderboard
      </span>
    ),
  },
  {
    value: 'Dashboard',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        dashboard
      </span>
    ),
  },
  {
    value: 'Person',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        person
      </span>
    ),
  },
  {
    value: 'School',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        shopping_bag
      </span>
    ),
  },
  {
    value: 'Campaign',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        campaign
      </span>
    ),
  },
  {
    value: 'Productivity',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        productivity
      </span>
    ),
  },
  {
    value: 'Phone In Talk',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        phone_in_talk_watchface_indicator
      </span>
    ),
  },
  {
    value: 'Tools Wrench',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        tools_wrench
      </span>
    ),
  },
  {
    value: 'Star',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        star
      </span>
    ),
  },
  {
    value: 'Settings Accessibility',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        settings_accessibility
      </span>
    ),
  },
  {
    value: 'View Chart',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        view_kanban
      </span>
    ),
  },
  {
    value: 'Patient List',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        patient_list
      </span>
    ),
  },
  {
    value: 'Group',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        group
      </span>
    ),
  },
  {
    value: 'Lock Person',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        lock_person
      </span>
    ),
  },
  {
    value: 'Interests',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        interests
      </span>
    ),
  },
  {
    value: 'Manage Accounts',
    label: (
      <span style={{ fontSize: '24px' }} className='material-symbols-outlined'>
        manage_accounts
      </span>
    ),
  },
];

const CoreSelectIcon = (props: PropsCoreSelect) => {
  const { name, label, control, loading, className, ...restProps } = props;

  const optionsFormat = optionsIcon.map((option: any, index: number) => {
    return {
      value: option?.value,
      label: <div dangerouslySetInnerHTML={{ __html: option?.label }} />,
    };
  });

  return (
    <div className={className}>
      {/* <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            allowClear={allowClear}
            loading={loading}
            onChange={field.onChange}
            className='w-full'
            options={optionsFormat}
          />
        )}
      /> */}
      <CoreSelect control={control} name={name} optionsProps={optionsFormat} {...restProps} />
    </div>
  );
};

export default CoreSelectIcon;
