import { get } from 'lodash';
import CoreSelect from '@/components/Form/SelectAntForm';
import React, { useCallback, useEffect } from 'react';
import useViewModel from '../../role/viewmodels/getAll';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any;
  name: string;
  className?: string;
  openForm: boolean;
  label?: string;
  required?: boolean;
}

export const RolesSelect = (props: Props) => {
  const { control, name, className, openForm, label, required } = props;

  const { roleData: allRoles, actionGetAll } = useViewModel();

  useEffect(() => {
    actionGetAll();
  }, [openForm]);

  const options = useCallback(() => {
    if (Array.isArray(allRoles.Role_getAll) && allRoles.Role_getAll.length > 0) {
      const formatData = allRoles.Role_getAll.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      return formatData;
    }
    return [];
  }, [allRoles]);

  return (
    <CoreSelect
      mode='multiple'
      control={control}
      name={name}
      optionsProps={options()}
      className={className}
      label={label}
      required={required}
      filterOption={(inputValue, option) => {
        if (option) {
          return String(get(option, 'label')).toLowerCase().includes(inputValue.toLowerCase());
        }
        return true;
      }}
    />
  );
};
