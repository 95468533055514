import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { CreateMenuItemsPayload } from '@/domain/ports/menu-items/payloads';
import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';

export class CreateMenuItemUseCase {
  constructor(private readonly repository: MenuItemsRepository) {}

  async create(payload: CreateMenuItemsPayload): Promise<MenuItemsEntity> {
    // validate check existed
    // const existedModel = await this.repo.getCustomerNeedName(payload.name);
    // if (existedModel) {
    //   throw new ValidationError('CustomerNeed with this name already exist');
    // }

    // Create model
    const menuItem = await this.repository.create(payload);

    return menuItem;
  }
}
