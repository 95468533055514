import { CoreInput } from '@/components/Form/InputAntForm';
import { CoreSwitch } from '@/components/Form/Switch';
import CoreSelectIcon from '@/components/helps/ListOptionsIcon';
import { Button, Typography } from 'antd';
import React, { useEffect } from 'react';
import useViewModel from '../../viewmodels/form';
import { DashboardEntity } from '@/domain/entities/dashboard';
import { useHandleReportLink } from '@/components/hooks/useHandleReportLink';
import { RolesSelect } from '../../components/RolesSelect';

interface Props {
  detailDashboard: DashboardEntity;
  setRefresh: (refresh: boolean) => void;
  openForm: boolean;
  showId: string | null;
}

export const DashboardForm = (props: Props) => {
  const { detailDashboard, openForm, showId } = props;
  const { methodForm, onSubmit, defaultValue, isError } = useViewModel(props);

  const {
    watch,
    control,
    setValue,
    reset,
    formState: { isSubmitting, isDirty },
  } = methodForm;
  const watchReportUrl = watch('reportLink');
  const { groupId, reportId } = useHandleReportLink(watchReportUrl);

  useEffect(() => {
    setValue('reportId', reportId);
    setValue('groupId', groupId);
  }, [reportId, groupId]);

  useEffect(() => {
    if (!showId) {
      reset(defaultValue);
    }
  }, [openForm]);
  return (
    <form onSubmit={onSubmit}>
      <div className='w-full flex flex-wrap'>
        <div className='w-full flex flex-wrap mb-4 justify-end'>
          <div className='text-left ml-6'>
            <Typography.Text className='w-full'>Status</Typography.Text>
            <CoreSwitch control={control} name='isActive' className='my-auto ' />
          </div>
        </div>

        <Typography.Text className='w-full'>Dashboard name (*)</Typography.Text>
        <CoreInput control={control} name='name' className='w-full mb-10' />

        <Typography.Text className='w-full'>Report link</Typography.Text>
        <CoreInput control={control} name='reportLink' className='w-full mb-10' />

        <div className='w-full flex flex-wrap justify-between mb-10'>
          <RolesSelect
            control={control}
            name='roles'
            openForm={true}
            label='Allowed role'
            className='w-1/3 mb-10 pr-6'
          />

          <div className='flex-auto'>
            <Typography.Text>URL (*)</Typography.Text>
            <CoreInput
              control={control}
              name='url'
              className='w-full mb-10'
              errorCustom={
                isError && <p className='italic text-[#F5222D]'>Dashboard with url already exist</p>
              }
            />
          </div>
        </div>
      </div>
      <div className='text-right'>
        <Button type='primary' htmlType='submit' loading={isSubmitting} disabled={!isDirty}>
          Save
        </Button>
      </div>
    </form>
  );
};
