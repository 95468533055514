import { AccountsEntity } from '@/domain/entities/account';
import { FindAccountPayload, UpdateAccountsPayload } from '@/domain/ports/account/payloads';
import { AccountsRepository } from '@/domain/ports/account/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { FIND_ACCOUNT, FIND_PERMISSION, GET_ACCOUNT_ITEMS, UPDATE_ACCOUNT } from '../../graphql';

export class AccountRepoIml implements AccountsRepository {
  async get(payload?: FindAccountPayload): Promise<{ data: AccountsEntity[] }> {
    const response: { data: AccountsEntity[] } = await HttpClientAdapter.query<{
      data: AccountsEntity[];
    }>({
      query: GET_ACCOUNT_ITEMS,
      dataKey: 'User_getList',
      variables: { query: { ...payload, limit: 1000 } },
    });
    return {
      data: response.data,
    };
  }

  async update(payload: UpdateAccountsPayload): Promise<AccountsEntity> {
    const response = await HttpClientAdapter.mutate<AccountsEntity>({
      mutation: UPDATE_ACCOUNT,
      variables: { payload: { ...payload } },
    });
    return response;
  }

  async find(payload: string): Promise<{ dataDetail: AccountsEntity }> {
    const response: AccountsEntity = await HttpClientAdapter.query<AccountsEntity>({
      query: FIND_ACCOUNT,
      dataKey: 'User_getById',
      variables: { id: payload },
    });
    return {
      dataDetail: response,
    };
  }
  async getPermission(): Promise<string[]> {
    const response: string[] = await HttpClientAdapter.query<string[]>({
      query: FIND_PERMISSION,
      dataKey: 'User_getPermission',
    });
    return response;
  }
}
