import { PageProvider } from '@/core/context/PageContext';
import useViewModel from '@/modules/dashboard/viewmodels/list';
import React, { useEffect } from 'react';
import { DashboardEntity } from '@/domain/entities/dashboard';
import { FindDashboardPayload } from '@/domain/ports/dashboard/payloads';
import DeleteDashboard from '../viewmodels/delete';

interface Data {
  dashboardList: DashboardEntity[];
  actionGetDashboard: (payload?: FindDashboardPayload) => void;
  actionDelete: (id: string) => void;
}

export const DashboardProvider = (props: any) => {
  const {
    dashboardData,
    actionGetDashboard,
    loading: loadingListDashboard,
    error: errorListDashboard,
  } = useViewModel();

  const {
    loading: loadingDeleteDashboard,
    error: errorDeleteDashboard,
    actionDelete,
    success: successDeleteDashboard,
    setSuccess,
  } = DeleteDashboard();
  const { dashboardList } = dashboardData;
  useEffect(() => {
    actionGetDashboard();
  }, []);
  const data = {
    dashboardList,
    actionGetDashboard,
    loadingListDashboard,
    errorListDashboard,
    actionDelete,
    setSuccess,
    loadingDeleteDashboard,
    errorDeleteDashboard,
    successDeleteDashboard,
  };

  return <PageProvider {...data}>{props.children}</PageProvider>;
};
