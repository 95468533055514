import React, { useEffect } from 'react';
import { MenuItemsColumn } from '@/domain/entities/menu-items';
import { useViewModels } from '@/modules/customer-needs/components/Table/ViewModel';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { notification, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHandleActionItem } from '../../../components/hooks/useHandleActionItem';
import useViewModel from '../viewmodels/delete';
import useViewModelList from '../viewmodels/list';
import { findIcons } from '../hooks/useIcon';
import { useAppContext, useCoreContext } from '@/core/hooks/useCoreContext';
import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useSSOAuthentication } from '@/core';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import { useCheckPermission } from '@/core/hooks/useCheckPermission';

export const MenuItemTableColumns = (setRefreshList?: any) => {
  const {
    actionGetMenuItem,
    loadingDeleteMenuItem,
    errorDeleteMenuItem,
    actionDelete,
    successDeleteMenuItem,
    setSuccess,
  } = useCoreContext();
  const { opacity, setOpacity } = useViewModels();
  const { handleClickEdit, showConfirmDelete } = useHandleActionItem();
  const { permission } = useAppContext();
  const { ensurePermission } = useCheckPermission();
  const renderContentDelete = () => {
    return (
      <>
        <p>Its child-items will be deactived.</p>
        <p>Are you sure to delete this menu item?</p>
      </>
    );
  };
  const isAllowEdit = ensurePermission(permission, PERMISSIONS.MENU_ITEMS.EDIT);
  const isAllowDelete = ensurePermission(permission, PERMISSIONS.MENU_ITEMS.REMOVE);
  const propsDelete = {
    content: renderContentDelete(),
    actionDelete: actionDelete,
    recordId: '',
    setRefreshList: setRefreshList,
  };

  useEffect(() => {
    if (!errorDeleteMenuItem && successDeleteMenuItem) {
      actionGetMenuItem();
      notification.success({
        message: 'Success',
        description: 'Delete MenuItem success',
        placement: 'bottomRight',
      });
      setSuccess(undefined);
    } else if (errorDeleteMenuItem && !successDeleteMenuItem) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(errorDeleteMenuItem),
        placement: 'bottomRight',
      });
      setSuccess(undefined);
    }
  }, [errorDeleteMenuItem, successDeleteMenuItem]);

  const tableColumns: ColumnsType<MenuItemsColumn> = [
    {
      title: 'Icon',
      dataIndex: 'icon',
      render: (record) => findIcons(record),
    },
    {
      title: 'Item Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Parent Item',
      dataIndex: 'parent',
      key: 'parent',
      render: (record) => {
        return record?.name;
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (value: boolean) => {
        if (value === true) {
          return <Typography.Text type='success'>Active</Typography.Text>;
        }
        return <Typography.Text disabled>Deactivated</Typography.Text>;
      },
    },
    {
      title: 'URL',
      dataIndex: 'report',
      key: 'report',
    },
    {
      title: 'Action',
      width: '6rem',
      key: 'action',
      render: (record) => {
        return (
          <div
            className='actions'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            {isAllowEdit ? (
              <EditOutlined
                style={{ color: 'blue', cursor: 'pointer', opacity }}
                onClick={() => handleClickEdit(record)}
              />
            ) : (
              ''
            )}
            {isAllowDelete ? (
              <DeleteOutlined
                style={{ color: 'red', cursor: 'pointer', opacity, paddingLeft: '8px' }}
                onClick={() => {
                  propsDelete.recordId = record.id;
                  return showConfirmDelete(propsDelete);
                }}
              />
            ) : (
              ''
            )}
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return tableColumns;
};
