import { useBaseViewModel } from '@/common';
import { DeleteDashboardUseCase } from '@/domain/use-cases/dashboard/delete';
import { DashboardRepoIml } from '../adapters/repositories';

export default function DeleteDashboard() {
  const deleteDashboard = new DeleteDashboardUseCase(new DashboardRepoIml());

  const { loading, error, catchAction, success, setSuccess } = useBaseViewModel();

  const actionDelete = (id: string) => {
    catchAction(async () => {
      await deleteDashboard.delete(id);
    });
  };

  return {
    loading,
    error,
    actionDelete,
    success,
    setSuccess,
  };
}
