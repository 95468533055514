import { useBaseViewModel } from '@/common';
import { DeleteMenuItemUseCase } from '@/domain/use-cases/menu-items/delete';
import { MenuItemRepoIml } from '../adapters/repositories';

export default function DeleteMenuItem() {
  const deleteMenuItem = new DeleteMenuItemUseCase(new MenuItemRepoIml());

  const { loading, error, catchAction, success, setSuccess } = useBaseViewModel();

  const actionDelete = (id: string) => {
    catchAction(async () => {
      await deleteMenuItem.delete(id);
    });
  };

  return {
    loading,
    error,
    actionDelete,
    success,
    setSuccess,
  };
}
