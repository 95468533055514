import React, { useEffect } from 'react';
import { getPermission } from '@/modules/accounts/viewmodels/getPermission';
import { authenticate, getCustomTokenFromSSOServer } from '@/core';
import { getUserByFirebaseId } from '@/modules/users/viewmodels/findFirebaseId';
import { UsersEntity } from '@/domain/entities/users';

export type AppContext = {
  permission: string[];
  userData: UsersEntity;
  hasPermission: (permission: string) => boolean;
  hasRole: (role: string) => boolean;
};

export interface AppContextProps {
  children: React.ReactNode;
}

export const AppContext = React.createContext({} as AppContext);

export const AppProvider: React.FC<AppContextProps> = ({ children }) => {
  const { actionGetPermission, permissionData } = getPermission();
  const { userData, actionGetUserByFirebaseId } = getUserByFirebaseId();
  const findUser = async () => {
    const customToken = await getCustomTokenFromSSOServer();
    const rs = await authenticate(customToken);
    const { userId } = rs;
    if (userId) {
      actionGetUserByFirebaseId(userId);
    }
  };
  useEffect(() => {
    actionGetPermission();
    findUser();
  }, []);

  const hasPermission = (permission: string) => {
    return permissionData.includes(permission);
  };

  const hasRole = (role: string) => {
    return userData.roles.includes(role);
  };

  return (
    <AppContext.Provider
      value={{
        permission: permissionData,
        userData,
        hasPermission,
        hasRole,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
