import React, { useEffect } from 'react';
import { DashboardColumn } from '@/domain/entities/dashboard';
import { useViewModels } from '@/modules/customer-needs/components/Table/ViewModel';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { notification, Tag, Typography } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import dayjs from 'dayjs';
import { RolesEntity } from '@/domain/entities/roles';
import { useAppContext, useCoreContext } from '@/core/hooks/useCoreContext';
import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useSSOAuthentication } from '@/core';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import { useCheckPermission } from '@/core/hooks/useCheckPermission';
import { get } from 'lodash';

export const DashboardTableColumns = () => {
  const {
    actionGetDashboard,
    loadingDeleteDashboard,
    errorDeleteDashboard,
    actionDelete,
    successDeleteDashboard,
    setSuccess,
  } = useCoreContext();
  const { opacity, setOpacity } = useViewModels();
  const { handleClickEdit, showConfirmDelete } = useHandleActionItem();
  const { permission } = useAppContext();
  const { ensurePermission } = useCheckPermission();
  const isAllowEdit = ensurePermission(permission, PERMISSIONS.DASHBOARDS.EDIT);
  const isAllowDelete = ensurePermission(permission, PERMISSIONS.DASHBOARDS.REMOVE);
  const renderContentDelete = () => {
    return (
      <>
        <p>Its dashboard will be deactived.</p>
        <p>Are you sure to delete this Dashboard?</p>
      </>
    );
  };
  useEffect(() => {
    if (!errorDeleteDashboard && successDeleteDashboard) {
      actionGetDashboard();
      notification.success({
        message: 'Success',
        description: 'Delete Dashboard success',
        placement: 'bottomRight',
      });
      setSuccess(undefined);
    } else if (errorDeleteDashboard && !successDeleteDashboard) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(errorDeleteDashboard),
        placement: 'bottomRight',
      });
      setSuccess(undefined);
    }
  }, [errorDeleteDashboard, successDeleteDashboard]);

  const propsDelete = {
    content: renderContentDelete(),
    actionDelete: actionDelete,
    recordId: '',
  };

  const tableColumns: ColumnsType<DashboardColumn> = [
    {
      title: 'Name',
      key: 'name',
      render: (record) => {
        return record.name;
      },
    },
    {
      title: 'Allowed role',
      key: 'roles',
      render: (record) => {
        if (Array.isArray(record.roles) && record.roles.length > 0) {
          return record.roles.map((item: RolesEntity) => {
            return <Tag key={get(item, 'id')}>{get(item, 'name')}</Tag>;
          });
        }
        return <></>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'status',
      render: (value: boolean) => {
        if (value === true) {
          return <Typography.Text type='success'>Active</Typography.Text>;
        }
        return <Typography.Text disabled>Deactivated</Typography.Text>;
      },
    },
    {
      title: 'Last update at',
      key: 'lastModifiedAt',
      render: (record) => {
        return dayjs(Number(record.lastModifiedAt)).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Create at',
      key: 'createdAt',
      render: (record) => {
        return dayjs(Number(record.createdAt)).format('DD/MM/YYYY');
      },
    },
    {
      title: 'Action',
      width: '6rem',
      key: 'action',
      render: (record) => {
        return (
          <div
            className='actions'
            onMouseOver={() => setOpacity(1)}
            onMouseLeave={() => setOpacity(0.6)}
          >
            {isAllowEdit ? (
              <EditOutlined
                style={{ color: 'blue', cursor: 'pointer', opacity }}
                onClick={() => handleClickEdit(record)}
              />
            ) : (
              ''
            )}
            {isAllowDelete ? (
              <DeleteOutlined
                style={{ color: 'red', cursor: 'pointer', opacity, paddingLeft: '8px' }}
                onClick={() => {
                  propsDelete.recordId = record.id;
                  return showConfirmDelete(propsDelete);
                }}
              />
            ) : (
              ''
            )}
          </div>
        );
      },
      fixed: 'right',
    },
  ];

  return tableColumns;
};
