export const PERMISSIONS = Object.freeze({
  APP: {
    VIEW: 'APP.VIEW',
  },
  ROLES: {
    VIEW: 'ROLES.VIEW',
    CREATE: 'ROLES.CREATE',
    EDIT: 'ROLES.EDIT',
    DELETE: 'ROLES.DELETE',
    SALESMAN: 'ROLES.SALESMAN',
    INTERVIEWER: 'ROLES.INTERVIEWER',
    VIEW_SCREEN: 'ROLES.VIEW_SCREEN',
    RECEPTIONIST: 'ROLES.RECEPTIONIST',
    ACCOUNTANT: 'ROLES.ACCOUNTANT',
    GENERALMANAGER: 'ROLES.GENERALMANAGER',
    MARKETER: 'ROLES.MARKETER',
    MARKETING_MANAGER: 'ROLES.MARKETING_MANAGER',
    ADMIN: 'ROLES.ADMIN',
    SALE_HO: 'ROLES.SALE_HO',
    SALEMANAGER: 'ROLES.SALEMANAGER',
    ASM: 'ROLES.ASM',
  },

  CUSTOMER_NEEDS: {
    CREATE: 'CUSTOMER_NEEDS.CREATE',
    EDIT: 'CUSTOMER_NEEDS.EDIT',
    VIEW: 'CUSTOMER_NEEDS.VIEW',
    REMOVE: 'CUSTOMER_NEEDS.REMOVE',
    VIEW_INACTIVE: 'CUSTOMER_NEEDS.VIEW_INACTIVE',
  },

  MENU_ITEMS: {
    CREATE: 'MENU_ITEMS.CREATE',
    EDIT: 'MENU_ITEMS.EDIT',
    VIEW: 'MENU_ITEMS.VIEW',
    REMOVE: 'MENU_ITEMS.REMOVE',
    VIEW_INACTIVE: 'MENU_ITEMS.VIEW_INACTIVE',
    VIEW_INACTIVE_PAGE: 'MENU_ITEMS.VIEW_INACTIVE_PAGE',
  },
  DASHBOARDS: {
    CREATE: 'DASHBOARDS.CREATE',
    EDIT: 'DASHBOARDS.EDIT',
    VIEW: 'DASHBOARDS.VIEW',
    REMOVE: 'DASHBOARDS.REMOVE',
    VIEW_INACTIVE: 'DASHBOARDS.VIEW_INACTIVE',
    VIEW_INACTIVE_PAGE: 'DASHBOARDS.VIEW_INACTIVE_PAGE',
  },
  COUNTRIES: {
    CREATE: 'COUNTRIES.CREATE',
    EDIT: 'COUNTRIES.EDIT',
    VIEW: 'COUNTRIES.VIEW',
    REMOVE: 'COUNTRIES.REMOVE',
    VIEW_INACTIVE: 'COUNTRIES.VIEW_INACTIVE',
  },
  CITIES: {
    CREATE: 'CITIES.CREATE',
    EDIT: 'CITIES.EDIT',
    VIEW: 'CITIES.VIEW',
    REMOVE: 'CITIES.REMOVE',
    VIEW_INACTIVE: 'CITIES.VIEW_INACTIVE',
  },
  DISTRICTS: {
    CREATE: 'DISTRICTS.CREATE',
    EDIT: 'DISTRICTS.EDIT',
    VIEW: 'DISTRICTS.VIEW',
    REMOVE: 'DISTRICTS.REMOVE',
    VIEW_INACTIVE: 'DISTRICTS.VIEW_INACTIVE',
  },
  WARDS: {
    CREATE: 'WARDS.CREATE',
    EDIT: 'WARDS.EDIT',
    VIEW: 'WARDS.VIEW',
    REMOVE: 'WARDS.REMOVE',
    VIEW_INACTIVE: 'WARDS.VIEW_INACTIVE',
  },
  JOBS: {
    CREATE: 'JOBS.CREATE',
    EDIT: 'JOBS.EDIT',
    VIEW: 'JOBS.VIEW',
    REMOVE: 'JOBS.REMOVE',
    VIEW_INACTIVE: 'JOBS.VIEW_INACTIVE',
  },
  SCHOOLS: {
    CREATE: 'SCHOOLS.CREATE',
    EDIT: 'SCHOOLS.EDIT',
    VIEW: 'SCHOOLS.VIEW',
    REMOVE: 'SCHOOLS.REMOVE',
    VIEW_INACTIVE: 'SCHOOLS.VIEW_INACTIVE',
  },
});
