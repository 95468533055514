import { useBaseViewModel } from '@/common';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { FindMenuItemByUseCase } from '@/domain/use-cases/menu-items/find';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MenuItemRepoIml } from '../adapters/repositories';

export default function GetDetailMenuItem() {
  const getDetailMenuItem = new FindMenuItemByUseCase(new MenuItemRepoIml());
  const [currentQueryParameters] = useSearchParams();
  const getId = currentQueryParameters.get('id');

  const { loading, error, catchAction } = useBaseViewModel();
  const [detail, setDetail] = useState<{ data: MenuItemsEntity }>({
    data: {
      id: '',
      name: '',
      parent: {
        id: '',
        name: '',
      },
      url: '',
      isActive: true,
      icon: '',
      level: 3,
      dashboardConfig: {
        name: '',
        id: '',
        roles: [],
        groupId: '',
        reportId: '',
        reportLink: '',
        url: '',
        isActive: true,
      },
      description: '',
    },
  });

  const actionGetDetail = () => {
    if (getId) {
      catchAction(async () => {
        const { dataDetail } = await getDetailMenuItem.find(getId);
        setDetail({ data: dataDetail });
      });
    }
  };

  return {
    loading,
    error,
    detail,
    actionGetDetail,
  };
}
