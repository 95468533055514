import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';

export class DeleteMenuItemUseCase {
  constructor(private readonly repository: MenuItemsRepository) {}

  async delete(payload: string): Promise<{ dataDeleted: MenuItemsEntity }> {
    const { dataDeleted } = await this.repository.delete(payload);

    return { dataDeleted };
  }
}
