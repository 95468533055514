import { Control } from 'react-hook-form';
import { CoreInput } from '../Form/InputAntForm';
import React from 'react';
import { Typography } from 'antd';

interface Props {
  control: Control;
  name?: string;
  className?: string;
}

export const CoreInputSearch = (props: Props) => {
  const { control, name = 'search', className, ...restProps } = props;

  return (
    <div className={className}>
      <Typography>Search</Typography>
      <CoreInput control={control} name={name} {...restProps} />
    </div>
  );
};
