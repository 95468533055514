import { useBaseViewModel } from '@/common';
import { TotalPickUpCallMetricEntity } from '@/domain/entities/real-time';
import { GetTotalPickupCallMetric } from '@/domain/use-cases/real-time/get-total-pickup-call-metric';
import { RealTimeRepository } from '@/modules/real-time/adapters/repositories';
import { useEffect, useState } from 'react';

export const useRealtimeDashboardViewModel = (interval = 30000) => {
  const [chartData, setChartData] = useState<TotalPickUpCallMetricEntity>({
    topUsers: [],
    totalPickUpCall: 0,
  });
  const useCase = new GetTotalPickupCallMetric(new RealTimeRepository());
  const { loading, error, catchAction } = useBaseViewModel();

  const fetchRealtimeData = async () => {
    console.log('Fetch realtime data');
    await catchAction(async () => {
      const data = await useCase.run();
      setChartData(data.RealTimeMetric_TotalPickUpCall);
    });
  };
  let fetchInterval: NodeJS.Timeout;
  const registerInterval = () => {
    console.log(`Register interval ${interval}`);
    fetchInterval = setInterval(() => {
      fetchRealtimeData();
    }, interval);
  };

  useEffect(() => {
    fetchRealtimeData();
    registerInterval();
    return () => {
      clearInterval(fetchInterval);
    };
  }, [interval]);

  return {
    chartData,
    loading,
    error,
  };
};
