import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';

export class FindMenuItemByUseCase {
  constructor(private readonly repository: MenuItemsRepository) {}

  async find(payload: string): Promise<{ dataDetail: MenuItemsEntity }> {
    const { dataDetail } = await this.repository.find(payload);

    return { dataDetail };
  }
}
