import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import CoreTable from '@/components/Table';
import { Button, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { DashboardTableColumns } from '../../components';
import { Detail } from '../Form';
import { CoreInputSearch } from '@/components/InputFilter/CoreInputSearch';
import { CoreFilterActive } from '@/components/InputFilter/CoreFilterActive';
import { useTableFilter } from '@/core/hooks/useTableFilter';
import { useAppContext, useCoreContext } from '@/core/hooks/useCoreContext';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import { useCheckPermission } from '@/core/hooks/useCheckPermission';

export const DashboardListScreen: React.FC<unknown> = () => {
  const {
    actionGetDashboard,
    loadingDeleteDashboard: loading,
    errorDeleteDashboard: error,
    dashboardList,
  } = useCoreContext();
  const [refresh, setRefresh] = useState(false);
  const tableColumns = DashboardTableColumns();
  const { open, handleClose, setOpen, handleClickCreate } = useHandleActionItem();
  const { permission } = useAppContext();
  const { ensurePermission } = useCheckPermission();
  const isAllowCreate = ensurePermission(permission, PERMISSIONS.DASHBOARDS.CREATE);

  const { methodFilter } = useTableFilter({
    handleFetchData: actionGetDashboard,
    querySearch: { search: '' },
    queryFilters: { isActive: null },
  });
  const { control } = methodFilter;

  useEffect(() => {
    actionGetDashboard();
  }, []);

  useEffect(() => {
    if (refresh === true) {
      actionGetDashboard();
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <div className='bg-white py-10 flex flex-col flex-wrap'>
        <div className='w-full flex flex-wrap mb-10 justify-between items-end'>
          <div className='flex flex-auto'>
            <CoreInputSearch control={control} className='w-1/3 px-6' />
            <CoreFilterActive control={control} className='w-32' />
          </div>
          {isAllowCreate ? (
            <Button
              type='primary'
              size='middle'
              onClick={() => handleClickCreate()}
              className='mr-6 rounded'
            >
              Create dashboard
            </Button>
          ) : (
            ''
          )}
          {/* <Button type='primary' size='large' onClick={() => actionGetDashboard()}>
          Refresh
        </Button> */}
        </div>
        <div className='text-center'>{loading && <Spin className='items-center' />}</div>
        {error && <div>Error</div>}
        {!error && (
          <div>
            <div className='mt-8 w-full px-6'>
              <CoreTable
                columns={tableColumns}
                dataSource={dashboardList ?? []}
                tableName='dashboard'
              />
            </div>
          </div>
        )}
        <Detail open={open} handleClose={handleClose} setOpen={setOpen} setRefresh={setRefresh} />
      </div>
    </>
  );
};
export const DashboardList = DashboardListScreen;
