import { Authorize } from '@/core';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import React from 'react';
import { DashboardProvider } from '../context';
import { DashboardList } from './List';

const IndexDashboard = () => {
  return (
    <DashboardProvider>
      <DashboardList />
    </DashboardProvider>
  );
};

export const UIndexDashboard = Authorize(IndexDashboard, PERMISSIONS.DASHBOARDS.VIEW);
