import React, { useEffect, useState } from 'react';
import CoreSelect from '@/components/Form/SelectAntForm';
import useViewModel from '../viewmodels/parent';
import { Spin } from 'antd';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { get } from 'lodash';

interface Props {
  control: any;
  name: string;
  className?: string;
  depth: number;
  openForm: boolean;
}

export const SelectMenuItemParent = (props: Props) => {
  const { control, name, className, depth, openForm } = props;
  const { loading, parent, actionGetParent } = useViewModel();

  useEffect(() => {
    actionGetParent({ depth: depth });
  }, [openForm, depth]);

  if (loading) {
    return <div>{loading && <Spin />}</div>;
  }

  return (
    <CoreSelect
      control={control}
      name={name}
      optionsProps={get(parent, 'dataList')}
      className={className}
      displayField='name'
      valueField='id'
    />
  );
};
