import { FindMenuItemPayload } from '@/domain/ports/menu-items/payloads';
import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';

export class MenuItemsListUseCase {
  constructor(private readonly repository: MenuItemsRepository) {}

  async run(payload?: FindMenuItemPayload) {
    const { data } = await this.repository.get(payload);
    return { data };
  }
}
