import { ReportConfigPayload } from '@/domain/entities/embed-report';
import { EmbedReportRepository } from '@/domain/ports/embed-report/repositories';

export class EmbedReportUseCase {
  constructor(private readonly repository: EmbedReportRepository) {}

  async run(payload: ReportConfigPayload) {
    const { data } = await this.repository.get(payload);
    return { data };
  }
}
