import { ReportConfigEntity, ReportConfigPayload } from '@/domain/entities/embed-report';
import { EmbedReportRepository } from '@/domain/ports/embed-report/repositories';
import { HttpClientAdapter } from '@/graphql/http-client-adapter';
import { GET_REPORT_ITEM_CONFIG } from '../../graphql';

export class EmbedReportRepoImpl implements EmbedReportRepository {
  async get(payload: ReportConfigPayload): Promise<{ data: ReportConfigEntity }> {
    const response: ReportConfigEntity = await HttpClientAdapter.query<ReportConfigEntity>({
      query: GET_REPORT_ITEM_CONFIG,
      dataKey: 'Report_getConfig',
      variables: { id: payload },
    });
    return {
      data: response,
    };
  }
}
