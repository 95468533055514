import { gql } from '@apollo/client';
import { ReportConfigData } from './reportConfigData';

export const GET_REPORT_CONFIG = gql`
  query {
    Report_getConfig(query: {
      refresh:true
      dashboardConfig: "6433e94623683312cf6d5556"
    }) {
      ${ReportConfigData}
    }
  }
`;

export const GET_REPORT = gql`
  query Report_getConfig($id: String!) {
    Report_getConfig(query: {
      refresh:true
      dashboardConfig: $id
    }) {
      ${ReportConfigData}
    }
  }
`;
