/**
 * Mỗi lần vào đọc file này chỉ muốn refactor hết sạch lại.
 * Má đọc code mà cay.
 */
import { REAL_TIME_REPORTS } from '@/config/real-time';
import { Authorize, useAppContext } from '@/core';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import {
  AppstoreAddOutlined,
  IdcardOutlined,
  SettingOutlined,
  SignalFilled,
} from '@ant-design/icons';
import { Layout, Typography } from 'antd';
import { get, flatMap } from 'lodash';
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useViewModel from '../../modules/menu-items/viewmodels/list';
import { menuIcon } from '../helps/ListOptionsIcon';
import type { MenuProps } from 'antd';
import { Menu } from '../Menu';
type Props = {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
};

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[] | undefined | null,
  disabled?: boolean,
): MenuItem =>
  ({
    key,
    icon,
    children,
    label,
    disabled,
  } as MenuItem);

const flattenTree = (tree: any, parentKey = null) => {
  return flatMap(tree, (node) => {
    const { children, ...rest } = node;
    const currentNode = { ...rest, parent: parentKey };
    const childNodes: any = flattenTree(children || [], node.id);
    return [currentNode, ...childNodes];
  });
};

const SidebarScreen = ({ collapsed, setCollapsed, setTitle }: Props) => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const { pageTree, actionGetTree, pageData, actionGetAll } = useViewModel();
  const { hasPermission } = useAppContext();
  const [allItemTree, setAllItemTree] = React.useState<MenuItem[]>([]);

  useEffect(() => {
    actionGetTree();
    actionGetAll({
      isActive: true,
      limit: 100,
    });
  }, []);

  const findIcon = (value: string) => {
    const Icon = menuIcon.find((item) => item.value === value);
    return Icon ? Icon.label : '';
  };

  const getMenuItem = (item: any) =>
    getItem(get(item, 'name'), get(item, 'dashboardConfig.url'), findIcon(get(item, 'icon')));

  const getSubMenus = (subMenu: any): MenuItem => {
    if (subMenu?.children?.length) {
      return getItem(
        subMenu.name,
        subMenu.dashboardConfig.url,
        findIcon(subMenu.icon),
        subMenu.children.map((item: any) =>
          item?.children?.length ? getSubMenus(item) : getMenuItem(item),
        ),
      );
    }
    return getMenuItem(subMenu);
  };

  const getMenuItemConfig = getItem(
    'Menu Item',
    '/menu-items',
    <IdcardOutlined style={{ fontSize: collapsed ? '24px' : '18px' }} />,
  );

  const getDashboardConfig = getItem(
    'Dashboard Management',
    '/dashboard-management',
    <AppstoreAddOutlined style={{ fontSize: collapsed ? '24px' : '18px' }} />,
  );

  useEffect(() => {
    const menus: MenuItem[] = pageTree.dataTree ? pageTree.dataTree.map(getSubMenus) : [];
    const systemMenus: MenuItem[] = [
      getItem(
        'Setting',
        '/setting',
        <SettingOutlined style={{ fontSize: collapsed ? '24px' : '18px' }} />,
        [
          hasPermission(PERMISSIONS.MENU_ITEMS.VIEW) ? getMenuItemConfig : null,
          hasPermission(PERMISSIONS.DASHBOARDS.VIEW) ? getDashboardConfig : null,
        ],
      ),
    ];
    // const realTimeMenus: MenuItem[] = REAL_TIME_REPORTS.map(item => {
    //   if (hasPermission(item.permission)) {
    //     const key = item.path ? item.path : '';
    //     return getItem(
    //       item.label,
    //       key,
    //       item.icon ? <item.icon /> : null,
    //       item.children.map((child) => {
    //         if (hasPermission(child.permission)) {
    //           const key = item.path ? item.path : '';
    //           return getItem(child.label, key);
    //         }
    //       }),
    //     );
    //   }
    // });
    const realTimeMenus: MenuItem[] = [];
    REAL_TIME_REPORTS.forEach((item) => {
      if (hasPermission(item.permission)) {
        const key = item.path ? item.path : '';
        const _child: MenuItem[] = [];
        if (item.children.length) {
          item.children.forEach((child) => {
            if (hasPermission(child.permission)) {
              const key = child.path ? child.path : '';
              _child.push(getItem(child.label, key));
            }
          });
        }
        realTimeMenus.push(getItem(item.label, key, item.icon ? <item.icon /> : null, _child));
      }
    });
    setAllItemTree([...realTimeMenus, ...menus, ...systemMenus]);
  }, [pageData, pageTree]);
  const setActiveKey = (selectedItem: any) => {
    const item = flattenTree(allItemTree).find((item) => {
      if (item) {
        return item.key === selectedItem.key;
      }
      return false;
    });
    if (item) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setTitle(item.label as string); // TODO: Fix this. I don't know why VuNa to do this. :sad_smile:.
    }
  };

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={setCollapsed}
      style={{ background: collapsed ? '#333' : '#000' }}
    >
      <Link to='/' className='text-white flex justify-center items-center h-[66px] gap-[10px]'>
        {collapsed ? (
          <SignalFilled
            className='w-[48px] h-[48px]'
            style={{ fontSize: '33px', lineHeight: '33px' }}
          />
        ) : (
          <>
            <SignalFilled />
            <Typography.Title className='text-white mb-0' level={4}>
              MindX Report
            </Typography.Title>
          </>
        )}
      </Link>
      <Menu
        onClick={({ key: path }) => {
          console.log(path);
          navigate(path.toString());
        }}
        onSelect={(selectedItem) => {
          setActiveKey(selectedItem);
        }}
        theme='dark'
        mode='inline'
        items={allItemTree}
      />
    </Sider>
  );
};

export const Sidebar = Authorize<Props>(SidebarScreen, PERMISSIONS.APP.VIEW);
