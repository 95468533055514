import { FieldTimeOutlined } from '@ant-design/icons';
import React from 'react';

interface RealTimeReportConfig {
  label: string;
  icon?: React.FC;
  permission: string;
  path?: string;
  children: RealTimeReportConfig[];
}

export const REAL_TIME_REPORTS: RealTimeReportConfig[] = [
  {
    label: 'Real Time',
    icon: FieldTimeOutlined,
    permission: 'APP.VIEW',
    children: [
      {
        label: 'Real Call Report',
        permission: 'APP.VIEW',
        path: '/real-time/call-metrics',
        children: [],
      },
    ],
  },
];
