import { useBaseViewModel } from '@/common';
import { DashboardEntity } from '@/domain/entities/dashboard';
import { DashboardListUseCase } from '@/domain/use-cases/dashboard';
import { useState } from 'react';
import { DashboardRepoIml } from '../adapters/repositories';
import { FindDashboardPayload } from '@/domain/ports/dashboard/payloads';

export default function GetListDashboard() {
  const getListDashboard = new DashboardListUseCase(new DashboardRepoIml());
  const { loading, error, catchAction } = useBaseViewModel();
  const [dashboardData, setDashboardData] = useState<{ dashboardList: DashboardEntity[] }>({
    dashboardList: [],
  });
  const actionGetDashboard = async (payload?: FindDashboardPayload) => {
    await catchAction(async () => {
      const { data } = await getListDashboard.run(payload);

      setDashboardData({ dashboardList: data });
    });
  };

  return {
    loading,
    error,
    dashboardData,
    actionGetDashboard,
  };
}
