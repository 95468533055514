import { Button } from 'antd';
import React from 'react';
import { config } from '@/config';
import { navigateOnFailures } from '@/common';

export const NoPermissionAccess: React.FC<unknown> = (): any => {
  const logout = (): void => {
    navigateOnFailures(false, true);
  };

  return (
    <>
      <div className='h-full text-center bg-white'>
        <img src={require('@/static/images/403.png')} className='m-auto'></img>
        <Button type='primary' className='mt-6 font-bold rounded' onClick={logout}>
          Go back
        </Button>
      </div>
    </>
  );
};
