import { gql } from '@apollo/client';
import { DashboardData } from './dashboard-data';

export const GET_DASHBOARD = gql`
query Dashboard_getList($query: QueryDashboardInput) {
  Dashboard_getList(query: $query) {
    ${DashboardData}
  }
}
`;

export const CREATE_DASHBOARD = gql`
mutation Dashboard_create($payload: CreateDashboardInput!) {
  Dashboard_create(createDashboardInput: $payload) {
    ${DashboardData}
  }
}
`;

export const EDIT_DASHBOARD = gql`
mutation Dashboard_update($payload: UpdateDashboardInput!) {
  Dashboard_update(updateDashboardInput: $payload) {
    ${DashboardData}
  }
}
`;

export const DELETE_DASHBOARD = gql`
  mutation Dashboard_delete($id: String!) {
    Dashboard_delete(id: $id)
  }
`;

export const FIND_DASHBOARD = gql`
  query Dashboard_findById($id: String!) {
    Dashboard_findById(id: $id) {
      ${DashboardData}
    }
  }
`;
