import { gql } from '@apollo/client';

export const REAL_TIME_METRIC_TOTAL_PICKUP_CALL = gql`
  query RealTimeMetric_TotalPickUpCall {
    RealTimeMetric_TotalPickUpCall {
      totalPickUpCall
      topUsers {
        userId
        metricName
        userName
        totalCalls
        lastUpdate
      }
    }
  }
`;
