import { useBaseViewModel } from '@/common';
import { AccountPermissionsUseCase } from '@/domain/use-cases/accounts/getPermission';
import { useState } from 'react';
import { AccountRepoIml } from '../adapters/repositories';

export const getPermission = () => {
  const getPermissionAccount = new AccountPermissionsUseCase(new AccountRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [permissionData, setPermissionData] = useState<string[]>([]);

  const actionGetPermission = async () => {
    await catchAction(async () => {
      const data = await getPermissionAccount.run();
      setPermissionData(data);
    });
  };

  return {
    loading,
    error,
    permissionData,
    actionGetPermission,
  };
};
