import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { initApolloClient } from './graphql';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
let client: ApolloClient<any>;
const test = async () => {
  client = await initApolloClient();
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
    </React.StrictMode>,
  );

  reportWebVitals();
};
test();
