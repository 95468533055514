import { Authorize } from '@/core';
import { PERMISSIONS } from '@/core/constants/permission.constant';
import React from 'react';
import { MenuItemProvider } from '../context';
import { MenuItemListScreen } from './List';

const UIMenuItemList = () => {
  return (
    <MenuItemProvider>
      <MenuItemListScreen />
    </MenuItemProvider>
  );
};

export const MenuItemList = Authorize(UIMenuItemList, PERMISSIONS.MENU_ITEMS.VIEW);
