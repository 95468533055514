/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Select, SelectProps, Typography } from 'antd';

export type PropsCoreSelect = SelectProps & {
  optionsProps: any[];
  placeholder?: string;
  control: any;
  name: string;
  displayField?: string;
  valueField?: string;
  loading?: boolean;
  style?: React.CSSProperties;
  className?: string;
  value?: any;
  allowClear?: boolean;
  disabled?: boolean;
  onChange?: (value: any) => void;
  mode?: 'tags' | 'multiple' | undefined;
  label?: string;
  required?: boolean;
  defaultActiveFirstOption?: boolean;
};

const CoreSelect = (props: PropsCoreSelect) => {
  const {
    optionsProps,
    name,
    control,
    loading,
    displayField = 'label',
    valueField = 'value',
    className = '',
    allowClear = true,
    mode = undefined,
    label,
    required,
    onChange,
    defaultActiveFirstOption = true,
    placeholder,
    ...restProps
  } = props;

  const optionsFormat = useCallback(() => {
    if (Array.isArray(optionsProps) && optionsProps.length > 0) {
      return optionsProps.map((option: any, index: number) => {
        return {
          value: option[valueField],
          label: option[displayField],
        };
      });
    }
    return optionsProps;
  }, [optionsProps]);
  return (
    <div className={className}>
      {label && (
        <Typography.Text className='w-full'>
          {label} {required ? '*' : ''}
        </Typography.Text>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
          return (
            <>
              <Select
                mode={mode}
                allowClear={allowClear}
                loading={loading}
                onChange={onChange}
                onBlur={onBlur}
                className='w-full'
                options={optionsFormat()}
                value={value}
                defaultActiveFirstOption={defaultActiveFirstOption}
                placeholder={placeholder}
                status={error ? 'error' : ''}
                {...restProps}
              />
              {error && <p className='italic text-[#F5222D]'>{error.message}</p>}
            </>
          );
        }}
      />
    </div>
  );
};

export default CoreSelect;
