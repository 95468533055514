export const MenuItemData = `
  id
  name
  parent{id name}
  isActive
  icon
  description
  level
  dashboardConfig {
  id
  name
  description
  groupId
  reportId
  reportLink
  isActive
  roles
  }
`;
