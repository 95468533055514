import { Switch as AntSwitch } from 'antd';
import React from 'react';
import { Controller } from 'react-hook-form';

export type SwitchProps = {
  control: any;
  name: string;
  className: string;
  loading?: boolean;
  status?: boolean;
  checked?: boolean;
};

export const CoreSwitch: React.FC<SwitchProps> = (props) => {
  const { status, control, name, loading, checked, className, ...nativeProps } = props;
  return (
    <div className={className}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return <AntSwitch onChange={onChange} loading={loading} checked={value} />;
        }}
      />
    </div>
  );
};
