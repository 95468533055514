import { DashboardEntity } from '@/domain/entities/dashboard';
import { DashboardRepository } from '@/domain/ports/dashboard/repositories';

export class FindDashboardByUseCase {
  constructor(private readonly repository: DashboardRepository) {}

  async find(payload: string): Promise<{ dataDetail: DashboardEntity }> {
    const { dataDetail } = await this.repository.find(payload);

    return { dataDetail };
  }
}
