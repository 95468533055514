import { MenuItemsRepository } from '@/domain/ports/menu-items/repositories';

export class MenuItemsGetTreeUseCase {
  constructor(private readonly repository: MenuItemsRepository) {}

  async run() {
    const { data } = await this.repository.getTree();

    return { data };
  }
}
