import { DashboardEntity } from '@/domain/entities/dashboard';
import { CreateDashboardPayload } from '@/domain/ports/dashboard/payloads';
import { DashboardRepository } from '@/domain/ports/dashboard/repositories';

export class UpdateDashboardUseCase {
  constructor(private readonly repository: DashboardRepository) {}

  async update(payload: CreateDashboardPayload): Promise<DashboardEntity> {
    const Dashboard = await this.repository.update(payload);

    return Dashboard;
  }
}
