import { UsersEntity } from '@/domain/entities/users';
import { UsersRepository } from '@/domain/ports/users/repositories';

export class FindFirebaseIdUsecase {
  constructor(private readonly repository: UsersRepository) {}

  async run(id: string): Promise<UsersEntity> {
    const user = await this.repository.findFirebaseId(id);

    return user;
  }
}
