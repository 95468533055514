import { getErrorMessage } from '@/components/hooks/useGetErrorMessage';
import { useHandleActionItem } from '@/components/hooks/useHandleActionItem';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { CreateMenuItemsPayload } from '@/domain/ports/menu-items/payloads';
import { CreateMenuItemUseCase } from '@/domain/use-cases/menu-items/create';
import { UpdateMenuItemUseCase } from '@/domain/use-cases/menu-items/update';
import yup from '@/yupGlobal';
import { yupResolver } from '@hookform/resolvers/yup';
import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { MenuItemRepoIml } from '../adapters/repositories';

interface Props {
  detailMenuItem: MenuItemsEntity;
  setRefresh: (refresh: boolean) => void;
}

const CreateMenuItem = (props: Props) => {
  const { detailMenuItem, setRefresh } = props;
  const { handleClose } = useHandleActionItem();
  const [currentQueryParameters] = useSearchParams();
  const showId = currentQueryParameters.get('id');
  const createMenuItem = new CreateMenuItemUseCase(new MenuItemRepoIml());
  const updateMenuItem = new UpdateMenuItemUseCase(new MenuItemRepoIml());
  const defaultValue = {
    id: '',
    name: '',
    parent: '',
    isActive: true,
    icon: '',
    dashboardConfig: '',
    description: '',
  };

  const methodForm = useForm({
    mode: 'onTouched',
    defaultValues: {
      id: detailMenuItem.id ?? '',
      name: detailMenuItem.name ?? '',
      parent: detailMenuItem?.parent?.id ?? null,
      isActive: detailMenuItem.isActive ?? true,
      icon: detailMenuItem.icon ?? '',
      dashboardConfig: detailMenuItem.dashboardConfig.id ?? '',
      description: detailMenuItem.description ?? '',
    },
    resolver: yupResolver(
      yup.object({
        name: yup
          .string()
          .required('Item name is required')
          .min(2, 'Too short')
          .max(40, 'Limited 40 character included: space, *,%,+,-,...'),
        dashboardConfig: yup.string().required('Url is required').trim(),
        icon: yup.string().required('Icon is required'),
      }),
    ),
  });

  const onSubmit = methodForm.handleSubmit(async (data: CreateMenuItemsPayload) => {
    try {
      if (!showId) {
        delete data.id;
        await createMenuItem.create(data);
        notification.success({
          message: 'You already have successfully created menu items',
          placement: 'bottomRight',
        });
      } else {
        await updateMenuItem.update(data);
        notification.success({
          message: 'You already have successfully updated menu items',
          placement: 'bottomRight',
        });
      }
      setRefresh(true);
      handleClose();
      methodForm.reset({
        ...defaultValue,
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: getErrorMessage(error),
        placement: 'bottomRight',
      });
    }
  });

  return {
    methodForm,
    onSubmit,
    defaultValue,
  };
};

export default CreateMenuItem;
