import { AppContext } from '@/core/context/AppContext';
import { useContext } from 'react';
import PageContext from '../context/PageContext';

export const useCoreContext = () => {
  return useContext(PageContext);
};

export const useAppContext = (): AppContext => {
  const context = useContext(AppContext);
  if (!context) throw new Error('useAppContext must be used within a AppProvider');
  return context;
};
