import { useDebounceFn, useUpdateEffect } from 'ahooks';
import { transform } from 'lodash';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';

interface Props {
  handleFetchData: (payload: any) => Promise<void>;
  querySearch: { [key: string]: string | object };
  queryFilters: { [key: string]: string | object | boolean | null };
}

export const useTableFilter = (props: Props) => {
  const { handleFetchData, querySearch = {}, queryFilters = {} } = props;
  const methodFilter = useForm({
    defaultValues: {
      ...querySearch,
      ...queryFilters,
    },
  });

  const { watch, getValues } = methodFilter;

  const watchInputs = watch(Object.keys(querySearch));

  const watchFilters = watch(Object.keys(queryFilters));

  const trimValue = useCallback((str: any) => {
    if (typeof str !== 'string') return str;
    return str.trim();
  }, []);

  const transformValues = useCallback((values = {}) => {
    const transformed = transform(values, (result: any, value: any, key) => {
      // typeof [] === 'object' too so need another condition
      if (typeof value === 'object' && !Array.isArray(value)) {
        result[key] = trimValue(value?.value);
      } else if (Array.isArray(value)) {
        result[key] = value.map((item) => {
          return trimValue(item);
        });
      } else {
        result[key] = trimValue(value);
      }
    });
    return transformed;
  }, []);

  const debounceFetchData = useDebounceFn(
    () => {
      const params: any = transformValues(getValues());
      handleFetchData({ ...params, page: 1 });
    },
    {
      wait: 500,
    },
  );

  useUpdateEffect(() => {
    debounceFetchData.run();
  }, [JSON.stringify(watchInputs)]);

  useUpdateEffect(() => {
    const params = transformValues(getValues());
    handleFetchData({ ...params, page: 1 });
  }, [JSON.stringify(watchFilters)]);

  return {
    methodFilter,
  };
};
