import { useBaseViewModel } from '@/common';
import { MenuItemsEntity } from '@/domain/entities/menu-items';
import { MenuItemsListUseCase, MenuItemsGetTreeUseCase } from '@/domain/use-cases/menu-items';
import { useState } from 'react';
import { MenuItemRepoIml } from '../adapters/repositories';
import { FindMenuItemPayload } from '@/domain/ports/menu-items/payloads';

export default function GetListMenuItem() {
  const getListMenuItem = new MenuItemsListUseCase(new MenuItemRepoIml());
  const getTreeMenuItem = new MenuItemsGetTreeUseCase(new MenuItemRepoIml());

  const { loading, error, catchAction } = useBaseViewModel();
  const [pageData, setPageData] = useState<{ dataList: MenuItemsEntity[] }>({ dataList: [] });
  const [pageTree, setPageTree] = useState<{ dataTree: MenuItemsEntity[] }>({ dataTree: [] });

  const actionGetAll = async (payload?: FindMenuItemPayload) => {
    await catchAction(async () => {
      const { data } = await getListMenuItem.run(payload);

      setPageData({ dataList: data });
    });
  };

  const actionGetTree = () => {
    catchAction(async () => {
      const { data } = await getTreeMenuItem.run();
      setPageTree({ dataTree: data });
    });
  };

  return {
    loading,
    error,
    pageData,
    pageTree,
    actionGetAll,
    actionGetTree,
  };
}
